import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {_labelStyles} from "../../../../styles/CommonStyles";
import { setShowJustificationDetails} from "../../../../actions/AbsenceActions";
import moment from 'moment';

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 320,
            maxHeight: 320,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class JustificationDetailsDialog extends React.Component {

    render() {
        const {classes, openDetails, JustificationDetails, detailsFor} = this.props;
        const {matricule, start, end, nom, prenom, justifiedAt, justifiedBy, justifierName, justifTypeLibelle, justifComment} = JustificationDetails;
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={openDetails}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                    }}
                    classes={{paper: classes.dialogPaper}}
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                Détails de la justification
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{marginTop: 25}}>
                        <Grid container direction={"row"}>
                            <Grid item sm={12}>
                                <Typography variant="body2" gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>
                                        {"Matricule / nom et prénom du collaborateur : "}
                                    </span>
                                    {matricule + " (" + prenom + " " + nom + " )"}
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container sm={12}>
                                    <Grid item sm={6}>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{ fontWeight: "bold" }}>
                                                {"Date de la justification : "}
                                            </span>
                                            {justifiedAt ? justifiedAt : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Typography variant="body2" gutterBottom>
                                            <span style={{ fontWeight: "bold" }}>
                                                {"Par : "}
                                            </span>
                                            {`${justifiedBy ? justifiedBy : ""} (${justifierName ? justifierName : ""})`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="body2" gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>
                                        {"Motif : "}
                                    </span>
                                    {justifTypeLibelle ? justifTypeLibelle : ""}
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="body2" gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>
                                        {"Date de l'absence : "}
                                    </span>
                                    {moment(start, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            {detailsFor === 'hourAbsence' && (<Grid container sm={12}>
                                <Grid item sm={6}>
                                    <Typography variant="body2" gutterBottom>
                                        <span style={{ fontWeight: "bold" }}>
                                            {"Heure début : "}
                                        </span>
                                        {moment(start, "DD/MM/YYYY HH:mm").format("HH:mm")}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant="body2" gutterBottom>
                                        <span style={{ fontWeight: "bold" }}>
                                            {"Heure fin : "}
                                        </span>
                                        {moment(end, "DD/MM/YYYY HH:mm").format("HH:mm")}
                                    </Typography>
                                </Grid>
                            </Grid>)}
                            <Grid item sm={12}>
                                <Typography variant="body2" gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>
                                        {"Commentaire : "}
                                    </span>
                                    {justifComment ? justifComment : ""}
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="body2" gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>
                                        {"Statut de l'absence : "}
                                    </span>
                                    <span style={{ color: "green" }}>
                                        justifée
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Grid container direction="row" justify="center" alignItems="center" sm={12}>
                            <Button onClick={() => {
                                this.props.setShowJustificationDetails({openDetails: false, details: {}})
                            }} color="primary">
                                Fermer
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {}
};

let mapActionsToProps = {
    setShowJustificationDetails
}

export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(JustificationDetailsDialog));
