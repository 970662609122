import axios from "axios";
import { environmentLocalDev, environmentPreprod, environmentProd, environmentStaging, environmentDev } from "../environments/Oauth";
import { store } from "../store/ConfigureStore";
import { getBackup, getMyuo, getReferences, getReferencesUser, getConfigUser } from "./ReferencesActions";
import { getEmployeeSolde, getSolde } from "./SoldeActions";
import { API, IS_PROD, IS_TL, OAUTH_LOGOUT_DEV, OAUTH_LOGOUT_PROD } from "../api/Endpoints";
import { getPendingRequestsCount } from "./RequestsActions";
import momentSetTimeZone from "../utilities/momentUtils";
import { setLanguage } from "../i18n/Strings";
import { configUser } from "../utilities/GlobalConstants";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Router } from "@material-ui/icons";


const envMapping = {
    "dev": environmentDev,
    "staging": environmentStaging,
    "preprod": environmentPreprod,
    "prod": environmentProd,
    "localDev": environmentLocalDev
};

const roleMappings = {
    "ROLE_RH_CONGE": "RH_VALIDATION",
    "ROLE_ADMIN_RH_CONGE": "RH_VALIDATION",
    "ROLE_PPV_CONGE": "PPV_VALIDATION",
    "ROLE_RP_CONGE": "PPV_VALIDATION",
    "ROLE_IDP_CONGE": "PPV_VALIDATION",
    "ROLE_MANAGER_CONGE": "MANAGER_VALIDATION",
    "ROLE_USER_NOPROD_CONGE": "MANAGER_VALIDATION",
};


const environment = envMapping[process.env.REACT_APP_ENV];

export function getAccessTokenRequest(code) {
    return async dispatch => {
        const axiosInstance = axios.create(
            {
                headers: {
                    'Authorization': `Basic ${encodeCredentials(environment.oauth.client_id, environment.oauth.secret)}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        const body = 'client_id=' + environment.oauth.client_id
            + '&grant_type=authorization_code'
            + '&credentials=true'
            + '&code=' + code
            + '&client_secret=' + environment.oauth.secret
            + '&scope=' + environment.oauth.scope
            + '&redirect_uri=' + environment.oauth.redirectTo;
        const res = await axiosInstance.post(environment.oauth.token, body);
        const { userProfile } = res.data;
        // const configUser = await axios.get(`${API}/conges/config`).catch(er => null);
        if (sessionStorage.getItem('language') != null){
            dispatch(getLanguage(setLanguage(sessionStorage.getItem('language'))));
        }else {
            dispatch(getLanguage(setLanguage(userProfile.codeLang)));
        }
        const authorities = userProfile.authorities;
        const authoritiesConge = authorities.filter(function (auth) {
            return auth.clientId === "conges";
        });
        dispatch(getAuthoritiesConge(authoritiesConge));
        dispatch(getProfileSuccess(userProfile)); // set profile to redux store
        saveInSession(environment.oauth.remoteUser, JSON.stringify(userProfile));
        saveInSession("ACCESS_TOKEN", userProfile.sectret2FA);
        dispatch(setFirstLoading(true));
        dispatch(getConfigUser()).then(() => {
            dispatch(getMyuo());
            dispatch(getSolde());
            dispatch(erroOccurs(false));
            dispatch(getReferencesUser());
            dispatch(getReferences());
            dispatch(getPendingRequestsCount());
            dispatch(setRoleMan(hasAuthority(["ROLE_MAN"]) && !hasAuthority(["ROLE_RH_CONGE"])))/*.catch(erroOccurs(true));*/
            dispatch(setFirstLoading(false));

        })
        .catch(e => {
            // dispatch(erroOccurs(true));
            dispatch(setFirstLoading(false));
        })
    }
}

export function getAxios() {
    const $access_token = getFromSession('ACCESS_TOKEN');
    const token1 = $access_token.substring(0, $access_token.length / 2);
    const token2 = $access_token.substring($access_token.length / 2, $access_token.length);
    const headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json; charset=utf-8",
            Intelcia1: token1,
            Intelcia2: token2
        }
    };
    const axiosInstance = axios.create(headers);
    axiosInstance.interceptors.request.use(function (config) {
        return config;
    }, function (error) {
        // return Promise.reject(error);
    });
    axiosInstance.interceptors.response.use(function (response) {
        return response;
    }, function (cerr) {
            if(500 == cerr.response && cerr.response.status){
                store.dispatch(erroOccurs(true));
            }else if(cerr.code == "ECONNABORTED") {
                    store.dispatch(erroOccurs(true));
            }else {
                return Promise.reject({...cerr}) 
            }
        
    });
    return axiosInstance;
}


export function getAuthorizationUrl(secret) {
    const callbackUrl = environment.oauth.redirectTo;
    const currentRoute = window.location.href;
    const code = getUriParam(currentRoute, "code");
    if (!/secret/.test(currentRoute)) {
        const uri = environment.oauth.authorize +
            '?response_type=' + environment.oauth.response_type +
            '&client_id=' + environment.oauth.client_id +
            '&scope=' + environment.oauth.scope +
            '&state=' + secret +
            '&redirect_uri=' + (typeof callbackUrl === undefined ? environment.oauth.redirectTo : callbackUrl);
        window.location.href = uri;
    } else {
        store.dispatch(getAccessTokenRequest(code));
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        momentSetTimeZone()
    }

}


function encodeCredentials(client_id, client_secret) {
    const credentials = client_id + ':' + client_secret;
    return btoa(credentials);
}

export function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getUriParam(uri, key) {
    if (typeof (url) === 'undefined') {
        let match = uri.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    } else {
        return false;
    }
}

export function getLanguage(payload) {
    return {
        type: "GET_LANGUAGE",
        payload
    }
}

export function getProfileSuccess(payload) {
    return {
        type: "GET_PROFILE_SUCCESS",
        payload
    }
}

export function getAuthoritiesConge(payload) {
    return {
        type: "GET_AUTHORITIES_CONGE",
        payload
    }
}

export function isLoggedIn(payload) {
    return {
        type: "IS_LOGGED_IN",
        payload
    }
}

export function setSearchActive(payload) {
    return {
        type: "SEARCH_ACTIVE",
        payload
    }
}

export function setLoading(payload) {
    return {
        type: "IS_LOADING",
        payload
    }
}

export function setFirstLoading(payload) {
    return {
        type: "IS_FIRST_LOADING",
        payload
    }
}


export function erroOccurs(payload) {
    return {
        type: "ERROR_OCCURS",
        payload
    }
}

export function setRoleMan(payload) {
    return {
        type: "IS_ROLE_MAN",
        payload
    }
}


export function hasPermission(permission) {
    let connectedUser = getCurrentUser();
    if (connectedUser === null || connectedUser === undefined) {
        return false;
    }
    let authorities = connectedUser.authorities;
    // check if user has authority
    for (let i in authorities) {
        if (authorities[i].clientId === "conges") {
            // check if user has permission
            let permissions = authorities[i].permissions;
            for (let i in permissions) {
                if (permissions[i] === permission) {
                    return true
                }
            }
        }
    }
    return false;
}

export function hasAuthority($authorities) {
    let connectedUser = getCurrentUser();
    if (connectedUser === null || connectedUser === undefined) {
        return false;
    }
    for (let elem of connectedUser.authorities) {
        const { clientId, authority } = elem;
        if ((clientId === "conges" && $authorities.includes(authority)) ||  $authorities.includes("ROLE_RH_CONGE_TEMPO")) {
            return true;
        }
    }
    return false;
}

export function getAuthorities() {
    let authorities = [];
    let connectedUser = getCurrentUser();
    if (connectedUser === null || connectedUser === undefined) {
        return false;
    }
    for (let elem of connectedUser.authorities) {
        const { clientId, authority } = elem;
        if (clientId === "conges") {
            authorities.push(authority)
        }
    }
    return authorities;
}

export const getConfigFromServer = async () => {
    const axios = getAxios();
    return axios.get(`${API}/conges/config`).catch(err => err);
}


export function rolesToValidation() {
    let listRoles = [];
    const authorities = getAuthorities();
    for (let authority of authorities) {
        if (Object.keys(roleMappings).includes(authority) && !listRoles.includes(roleMappings[authority])) {
            listRoles.push(roleMappings[authority])
        }
    }
    return listRoles;
}


export function rolesToAdmin() {
    const roleMappings = {
        "ROLE_ADMIN_PPV_CONGE": { value: "ADMIN_PPV", label: "Admin PPV" },
        "ROLE_ADMIN_RH_CONGE": { value: "ADMIN_RH", label: "Admin RH" },
    };
    let d = [];
    const authorities = getAuthorities();
    for (let authority of authorities) {
        if (Object.keys(roleMappings).includes(authority)) {
            return roleMappings[authority]
        }
    }

}

function encodeQuery(data) {
    let ret = [];
    for (let d in data)
        ret.push("role=" + data[d]);
    return (ret.length > 0) ? ret.join("&") : "role=MANAGER_VALIDATION"
}

export function rolesToValidationUrl() {
    const roles = [];
    const authorities = getAuthorities();
    for (let authority of authorities) {
        if (Object.keys(roleMappings).includes(authority)) {
            roles.push(roleMappings[authority]);
            return encodeQuery(roles)
        }
    }

}

export function $rolesToValidationUrl() {
    const roles = [];
    const authorities = getAuthorities();
    for (let authority of authorities) {
        if (Object.keys(roleMappings).includes(authority)) {
            roles.push(roleMappings[authority])
        }
    }
    return encodeQuery([...new Set(roles)])
}

export const getIsProd = async (registrationNumber) => {
    const axios = getAxios();
    return axios.get(`${API}/${IS_PROD}?user=${registrationNumber}`);
};

export const getIsTL = async (registrationNumber) => {
    const axios = getAxios();
    return axios.get(`${API}/${IS_TL}?user=${registrationNumber}`);
};

export function hasAllAuthorities($authorities) {
    let connectedUser = getCurrentUser();
    if (connectedUser === null || connectedUser === undefined) {
        return false;
    }
    for (let elem of connectedUser.authorities) {
        const { clientId, authority } = elem;
        if (clientId === "conges" && $authorities.includes(authority) === false) {
            return false;
        }
    }
    return true;
}

export function getCurrentUser() {
    return store.getState().auth.profile;
}

export function logout() {
    window.location.href = ["preprod", "localDev"].includes(process.env.REACT_APP_ENV) ? OAUTH_LOGOUT_DEV : OAUTH_LOGOUT_PROD;
}

//
// function getAccessToken(code) {
//     if (!hasValidAccessToken()) {
//         return getAccessTokenRequest(code).map(userProfile => {
//             saveInSession(environment.oauth.remoteUser, JSON.stringify(userProfile));
//             return userProfile;
//         });
//     }
// }
//>
// function hasValidAccessToken() {
//     return getFromSession('ACCESS_TOKEN') !== null;
// }


export function saveInSession(key, value) {
    sessionStorage.setItem(key, value);
}

export function getFromSession(key) {
    return sessionStorage.getItem(key);
}

export function removeFromSession(key) {
    return sessionStorage.removeItem(key);
}


