
export const environmentStaging = {
    production: false,
    api: 'https://test-myvacation.intelcia.com/api',
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://test-oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://test-oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: 'https://test-myvacation.intelcia.com',
    }
};


export const environmentDev = {
    production: false,
    api: 'https://dev-myvacation.intelcia.com/api',
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://dev-oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://dev-oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: 'https://dev-myvacation.intelcia.com',
    }
};


export const environmentPreprod = {
    production: false,
    api: '/api',
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://preprod-oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://preprod-oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: new URL(window.location.href).origin,
    }
};




export const environmentProd = {
    production: true,
    api: 'https://ms-myconges.intelcia.com',
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: 'https://myvacation.intelcia.com',
    }
};


export const environmentLocalDev = {
    production: true,
    api: 'https://preprod-myvacation.intelcia.com/api',
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://oauth.dev.intelcia.com/uaa/oauth/token',
        authorize: 'https://oauth.dev.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '79c4aV31X8Eg52c',
        client_id: 'intranet-liferay',
        response_type: 'code',
        redirectTo: 'http://localhost:3000',
    }
};

