import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import moment from 'moment';
const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 200,
            maxHeight: 200,
            maxWidth: 400,
            minWidth: 400,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getMenuItems(items) {
    return items.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
}


class ExportDialog extends React.Component {
    state = {
        start: null,
        end: null
    };


    async componentWillMount() {
        const { rows, exportIsOpen } = this.props;
    }

    componentWillUnmount() {
    }

    download(AllRows) {
        let data = "Matricule,Nom,Prenom,date Absence,heure debut,heure de fin\n";
        let rows = AllRows.filter(e => moment(e.start, "DD/MM/YYYY hh:mm").isBetween( moment(this.state.start).subtract('days',1),moment(this.state.end).add('days',1)))
        for(let i in rows) {
            data += `${rows[i].matricule},${rows[i].nom},${rows[i].prenom},${moment(rows[i].start, "DD/MM/YYYY hh:mm").format('DD/MM/YYYY') || ''},${rows[i].end ? moment(rows[i].start, "DD/MM/YYYY hh:mm").format('hh:mm') : ''},${ rows[i].end ? moment(rows[i].end, "DD/MM/YYYY hh:mm").format('hh:mm') : ''}\n`;
        }
        const csvContent = `data:text/csv;charset=utf-8,${data}`;
        const encodedURI = encodeURI(csvContent);
        window.open(encodedURI,"export.csv");
    };

    render() {
        const { classes, exportIsOpen , rows } = this.props;
        const { start, end } = this.state;
        return (
            <div style={{ position: "relative" }}>
                <Dialog
                    open={exportIsOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => { }}
                    classes={{ paper: classes.dialogPaper }}
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                Rapport des absences injustifiées
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{ marginTop: 30 }}>
                        <Grid container direction={"row"} spacing={32}>

                            <Grid item sm={6}>
                                <label>Date debut</label>
                                <input
                                    type="date"
                                    onChange={(e) => {
                                        const newDate = moment(new Date(e.target.value));
                                        this.setState({start : newDate})
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <label>Date de fin</label>
                                <input
                                    type="date"
                                    onChange={(e) => {
                                        const newDate = moment(new Date(e.target.value));
                                        this.setState({end : newDate})
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.toggleExport();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.download(rows);
                            this.props.toggleExport();
                        }} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ExportDialog);
