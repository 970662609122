import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import connect from "react-redux/es/connect/connect";
import {withStyles} from '@material-ui/core/styles';
import {justifyAbsence, setShowAddJustification} from "../../../../actions/AbsenceActions";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import {_labelStyles} from "../../../../styles/CommonStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {API, EXCEPTIONAL_TYPES_FR} from "../../../../api/Endpoints";
import {getAxios} from "../../../../actions/AuthActions";

const styles = theme => {
    return {
        dialogPaper: {
            minHeight: 550,
            maxHeight: 550,
            maxWidth: 800,
            minWidth: 800,
        },
        dialogContent: {
            marginTop: 100
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function getMenuItems(items) {
    return items.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
}


class AddJustificationDialog extends React.Component {
    state = {
        id: "",
        idMyshift: "",
        justified: false,
        justifType: "",
        justifTypeVal: "",
        matricule: "",
        start: "",
        end: "",
        comment: "",
        daily: null,
        types: []
    };

    handleChange = (value, name) => {
        if (name === "justifType") {
            const justifTypeVal = value.split(";")[0];
            this.setState({[name]: value, justifTypeVal});
        } else {
            this.setState({[name]: value});
        }
    };


    async componentWillMount() {
        const {details, open} = this.props;
        const {id, idMyshift, justified, justifType, matricule, start, end, comment, daily, nom, prenom} = details;
        this.setState({id, idMyshift, justified, justifType, matricule, start, end, comment, daily, nom, prenom});
        const axios = getAxios();
        const types = await axios.get(`${API}/${EXCEPTIONAL_TYPES_FR}`);
        console.log('types', types)
        this.setState({
            types: types.data.filter(type => type.forJustifyHour === true).map(type => {
                return {label: type.label, value: (typeof type.mappingHR !== 'undefined') ? type.mappingHR.abbreviation+";"+type.label: ";"+type.label}
            })
        });
    }

    componentWillUnmount() {
        this.setState({matricule: null, start: null, end: null, comment: null})
    }

    render() {
        const {classes, open} = this.props;
        const {matricule, start, end, comment, justified, types, justifType, justifTypeVal, idMyshift, daily, id, nom, prenom} = this.state;
        return (
            <div style={{position: "relative"}}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                    }}
                    classes={{paper: classes.dialogPaper}}
                >
                    <AppBar position="static" color="primary" elevation={0}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit">
                                Ajout justification horaire Myshift
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{marginTop: 30}}>
                        <Grid container direction={"row"} spacing={32}>
                            <Grid item sm={12}>
                                <InputLabel style={_labelStyles} htmlFor="matricule">Matricule</InputLabel>
                                <TextField
                                    id={"matricule"}
                                    onChange={(e) => {
                                        this.handleChange(e.target.value, "label")
                                    }}
                                    autoFocus
                                    value={matricule}
                                    margin="dense"
                                    disabled
                                    fullWidth
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <InputLabel
                                    style={{marginLeft: 0, fontSize: 11}}
                                >
                                    Justifée
                                </InputLabel>
                                <Select
                                    fullWidth
                                    disabled
                                    className={classes.input}
                                    value={true}
                                    onChange={e => {
                                        this.handleChange(e.target.value, "justified")
                                    }}
                                >
                                    {getMenuItems([
                                        {value: true, label: "Justifée"},
                                        {value: false, label: "Non justifiée"},
                                    ])}
                                </Select>
                            </Grid>

                            <Grid item sm={6}>
                                <InputLabel
                                    style={{marginLeft: 0, fontSize: 11}}
                                >
                                    Justification
                                </InputLabel>
                                <Select
                                    fullWidth
                                    className={classes.input}
                                    value={justifType}
                                    onChange={e => {
                                        this.handleChange(e.target.value, "justifType")
                                    }}
                                >
                                    {getMenuItems(types)}
                                </Select>
                            </Grid>

                            <Grid item sm={6}>
                                <InputLabel style={_labelStyles} htmlFor="start">Départ</InputLabel>
                                <TextField
                                    disabled
                                    id={"start"}
                                    onChange={(e) => {
                                        this.handleChange(e.target.value, "start")
                                    }}
                                    fullWidth
                                    value={start}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <InputLabel style={_labelStyles} htmlFor="end">Retour</InputLabel>
                                <TextField
                                    disabled
                                    id={"end"}
                                    onChange={(e) => {
                                        this.handleChange(e.target.value, "end")
                                    }}
                                    fullWidth
                                    value={end}
                                />

                            </Grid>
                            <Grid item sm={12}>
                                <InputLabel
                                    style={{marginLeft: 0, fontSize: 11}}
                                >
                                    Commentaire
                                </InputLabel>
                                <TextField
                                    value={comment}
                                    onChange={e => {
                                        this.handleChange(e.target.value, "comment")
                                    }}
                                    multiline
                                    fullWidth
                                    rows="4"
                                />
                            </Grid>

                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            this.props.setShowAddJustification({open: false, details: {}})
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => {
                            this.props.justifyAbsence(id, idMyshift, matricule, nom, prenom, start, end, justifTypeVal, justified, daily, comment);
                            this.props.setShowAddJustification({open: false, details: {}});
                        }} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {absence: state.absence}
};

let mapActionsToProps = {
    setShowAddJustification,
    justifyAbsence
};


export default withStyles(styles)(connect(mapStateToProps, mapActionsToProps)(AddJustificationDialog));
