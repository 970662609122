import Toolbar from "@material-ui/core/Toolbar/index";
import React from "react";
import { getTimeLine, setTimeLineStartDate } from "../../../actions/HolidaysActions";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import * as moment from "moment";
import { DayPickerRangeController } from "react-dates";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "../../../styles/DatePicker.css";
import { withStyles } from "@material-ui/core";
import { strings } from "../../../i18n/Strings";
import "moment/locale/fr";
import IconButton from "@material-ui/core/IconButton";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  dialogPaper: {
    minHeight: 350,
    maxHeight: 350,
    minWidth: 370,
    maxWidth: 370
  }
};
const { _myUO } = strings;
const emptyUO = {
  checked: false,
  children: [],
  class: "",
  code: "",
  codeSirh: "",
  deleted: false,
  description: "",
  enabled: false,
  exportSirhEnum: "",
  id: null,
  keys: [],
  name: _myUO,
  shortName: "",
  sites: [],
  uoLevel: ""
};

// const {lang} = strings;
//
// moment.locale(lang);

class TimeLineCalendarToolbar extends Toolbar {
  state = {
    showCircularProgress: false,
    focusedInput: "startDate",
    selectedUo: emptyUO,
    focused: false,
    startDate: "",
    open: false,
    monthDiffCounter: 0,
    currentMonth: moment()
      .startOf("month")
      .format("DD/MM/YYYY")
  };

  handleChange = uo => {
    this.setState({ selectedUo: uo });
    this.props.getTimeLine(
      moment(this.props.timeLineStartDate)
        .startOf("month")
        .format("DD/MM/YYYY"),
      uo.id,
      moment(this.props.timeLineStartDate)
        .endOf("month")
        .format("DD/MM/YYYY")
    );
  };
  toggleCalendar = () => {
    this.setState({ open: !this.state.open });
  };

  handleTimeLineSelect(startDate, endDate) {
    this.props.setTimeLineStartDate(startDate);
    this.setState({ startDate, endDate });
    this.props.getTimeLine(
      moment(startDate).format("DD/MM/YYYY"),
      this.state.selectedUo.id,
      moment(endDate).format("DD/MM/YYYY")
    );
  }

  getUOList(UOList) {
    if (UOList == null || UOList.length === 0)
      return <MenuItem value={emptyUO}>{emptyUO.name}</MenuItem>;
    return UOList.map((uo, index) => (
      <MenuItem key={index} value={uo}>
        {" "}
        {uo.name}
      </MenuItem>
    ));
  }

  onDatesChange = (startDate, endDate) => {
    this.setState({
      startDate: moment(startDate).startOf("month"),
      endDate: moment(startDate).endOf("month")
    });
  };

  navigate = action => {
    this.props.onNavigate(action);
  };

  render() {
    const { classes } = this.props;
    const { uo } = this.props.references;
    const {authoritiesConge, profile }=this.props.auth;
    const myUoLeaves = profile.role != 'CI' && profile.role != 'CC'  ? 
    <>
    <Grid item>
      <Typography variant="h6">{_myUOHolidays}</Typography>
      </Grid>
      <Grid item>
        <InputLabel htmlFor="uo">UO :</InputLabel>
        <Select
          style={{ width: 300, paddingTop: 5 }}
          value={selectedUo}
          inputProps={{
            name: "uo",
            id: "uo"
          }}
          onChange={event => {
            this.handleChange(event.target.value);
          }}
        >
          {this.getUOList(uo)}
        </Select>
      </Grid>
      </> : '';
    console.log(myUoLeaves)
    let {
      selectedUo,
      startDate,
      focused,
      open,
      monthDiffCounter,
      showCircularProgress
    } = this.state;
    const { _myUOHolidays, _lang } = strings;
    return (
      <div>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar style={{ minHeight: 40, height: 40, background: "#fff" }}>
            <Grid container justify={"space-around"} alignItems={"center"}>
              {myUoLeaves}
              <Grid item>
                <Grid container>
                  <Grid item>
                    {/*<IconButton*/}
                    {/*onClick={() => {*/}
                    {/*this.navigate("PREV");*/}
                    {/*let currentCount = monthDiffCounter - 1;*/}
                    {/*const date = moment()*/}
                    {/*.add(currentCount, "months")*/}
                    {/*.startOf("month");*/}
                    {/*this.setState({*/}
                    {/*monthDiffCounter: currentCount,*/}
                    {/*startDate: date,*/}
                    {/*endDate: moment(date).endOf("month")*/}
                    {/*});*/}
                    {/*this.handleTimeLineSelect(date);*/}
                    {/*}}*/}
                    {/*aria-label="back"*/}
                    {/*className={classes.button}*/}
                    {/*>*/}
                    {/*<ArrowBack />*/}
                    {/*</IconButton>*/}
                    <IconButton
                      onClick={() => {
                        this.setState({ open: true });
                      }}
                      aria-label="calendar"
                      className={classes.button}
                    >
                      <CalendarIcon/>
                    </IconButton>
                    {/*<IconButton*/}
                    {/*onClick={() => {*/}
                    {/*this.navigate("PREV");*/}
                    {/*let currentCount = monthDiffCounter + 1;*/}
                    {/*const date = moment()*/}
                    {/*.add(currentCount, "months")*/}
                    {/*.startOf("month");*/}
                    {/*this.setState({*/}
                    {/*monthDiffCounter: currentCount,*/}
                    {/*startDate: date,*/}
                    {/*endDate: moment(date).endOf("month")*/}
                    {/*});*/}
                    {/*this.handleTimeLineSelect(date);*/}
                    {/*}}*/}
                    {/*aria-label="forward"*/}
                    {/*className={classes.button}*/}
                    {/*>*/}
                    {/*<ArrowForward />*/}
                    {/*</IconButton>*/}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant={"body2"}
                      style={{ paddingLeft: 5, marginTop: 15 }}
                    >
                      {this.props.label}
                    </Typography>
                  </Grid>
                </Grid>
                <Dialog
                  open={open}
                  onClose={this.toggleCalendar}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  classes={{ paper: classes.dialogPaper }}
                >
                  <DialogContent>
                    <div style={{ position: "relative" }}>
                      <DayPickerRangeController
                        numberOfMonths={1}
                        // withPortal={true}
                        locale={"fr"}
                        hideKeyboardShortcutsPanel={true}
                        startDate={startDate}
                        endDate={moment(startDate).add(1, "months")}
                        onDatesChange={({ startDate, endDate }) => {
                          this.onDatesChange(startDate, endDate);
                          let _monthDiffCounter =
                            moment(startDate).startOf("month") ===
                            moment(endDate).startOf("month")
                              ? 0
                              : parseInt(
                              moment(startDate).diff(
                                endDate,
                                "months",
                                true
                              )
                              );

                          this.setState({
                            showCircularProgress: true,
                            monthDiffCounter: _monthDiffCounter
                          });
                          this.handleTimeLineSelect(
                            moment(startDate).startOf("month"),
                            moment(startDate).endOf("month")
                          );

                          setTimeout(() => {
                            this.setState({
                              showCircularProgress: false,
                              open: false
                            });
                          }, 1000);
                        }}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={focusedInput =>
                          this.setState({ focusedInput: "startDate" })
                        }
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "45%"
                        }}
                      >
                        {showCircularProgress && (
                          <CircularProgress size={40} color="primary"/>
                        )}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateTopProps = state => {
  return { 
    references: state.references,
    auth: state.auth
   };
};

const mapActionsToprops = { getTimeLine, setTimeLineStartDate };

export default withStyles(styles)(
  connect(
    mapStateTopProps,
    mapActionsToprops
  )(TimeLineCalendarToolbar)
);
