const initialRequestState = {loading: false, data: [], details: {}, totalElements: 0};

export default function absenceReducer(state = {
    showAddJustification: {open: false, details: {}},
    showJustificationDetails: {openDetails: false, JustificationDetails: {}},
    showSpliter: {show: false, spliterDetails: {}},
    showAbsenceDetails:{
        open: false,
        status: "",
        data : {}
    },
    deleteTimeEntryDetails:{
        open: false,
        status: "",
        data : {}
    },
    dayAbsence: {
        loading: false,
        details: {},
        totalElements: 0,
        data: []
    },
    hourAbsence: {
        loading: false,
        data: [],
        details: {},
        totalElements: 0
    },
    timeTrajet: {
        loading: false,
        data: [],
        details: {},
        totalElements: 0
    },
    timeDelegation: {
        loading: false,
        data: [],
        details: {},
        totalElements: 0
    },
    justificationTypes: {
        loading: false,
        data: null,
    }
}, {type, payload}) {
    switch (type) {
        case "GET_ABSENCE_BY_DAY":
            return {...state, dayAbsence: payload};
        case "GET_ABSENCE_BY_HOUR":
            return {...state, hourAbsence: payload};
        case "SET_SHOW_ADD_JUSTIFICATION":
            return {...state, showAddJustification: payload};
        case "SET_SHOW_JUSTIFICATION_DETAILS":
            return {...state, showJustificationDetails: payload};
        case "SET_SHOW_SPLITER":
            return {...state, showSpliter: payload};
        case "GET_TIME_TRAJET":
            return {...state, timeTrajet: payload};
        case "GET_TIME_DELEGATION":
            return {...state, timeDelegation: payload};
        case "SHOW_TIME_ENTRY_DETAILS":
            console.log("payload 2 -----",payload)
            return {...state, showAbsenceDetails: payload};
        case "SHOW_DELETION_DIALOG":
            return {...state, deleteTimeEntryDetails: payload};
        case "GET_JUSTIFICATION_TYPES":
            return {...state, justificationTypes: payload};
        default:
            return state
    }
}


