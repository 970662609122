import React from "react";
import RefData from "./RefData";
import Avatar from "@material-ui/core/Avatar";
import {
  API,
  FLAGS_CDN,
  MEDIUM_AVATAR_CORE_SERVER
} from "../api/Endpoints";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import MGrid from "@material-ui/core/Grid";
import { strings } from "../i18n/Strings";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import "../styles/FilterStyles.css";
import TableCell from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import * as moment from "moment-timezone";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { store } from "../store/ConfigureStore";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import { hasAuthority } from "../actions/AuthActions";
import { MIN_FR_REG_LENGTH } from "./GlobalConstants";
import _ from 'lodash'

const {
  _payedHoliday,
  _unpayedHoliday,
  _exceptionalHoliday,
  _minor,
  _major,
  _normal,
  _recuperation,
  _deleted,
  _approved,
  _pending,
  _rejected,
  _canceled,
  _cancelationRequest,
  _absenceRequest,
  _enabled,
  _disabled,
  _added,
  _all,
  _hr,
  _initializer,
  _validator,
  _system,
  _unpaidSicknessHoliday,
  _INTERNAL_SERVER_ERROR,
  _ppv_system,
  _casualHoliday
} = strings;

export const startDatePredicate = (value, filter) => {
    console.log('startDatePredicate:', value, " | ", filter)
  return !moment(filter.value).isAfter(value);
};

export const endDatePredicate = (value, filter) => {
  return !moment(value).isAfter(filter.value);
};

export const noPredicate = (value, filter) => {
  return true;
};

export const compareDates = (a, b) => {
  const dateA = moment(formatDate(a));
  const dateB = moment(formatDate(b));
  return (dateA.isBefore(dateB)) ? -1 : 1;
};

export const compareDateTimes = (a, b) => {
  const dateA = moment(a, "DD/MM/YYYY HH:mm");
  const dateB = moment(b, "DD/MM/YYYY HH:mm");
  return (dateA.isBefore(dateB)) ? -1 : 1;
};

export function formatDate(date) {
  let dl = date.split("/");
  return `${dl[2]}-${dl[1]}-${dl[0]}`;
}

// export const onChangeDebounceFilter = () => _.debounce(filters => this.changeFilters(filters), 500);


export function formatDate_DD_MM_YYYY(date) {
  let dl = date.split("/");
  return `${dl[0]}-${dl[1]}-${dl[2]}`;
}

export function formatBackDate(date) {
  let dl = date.split("-");
  return `${dl[2]}/${dl[1]}/${dl[0]}`;
}

export const getRole = role => {
  const {
    _hr,
    _initializer,
    _validator,
    _system,
    _ppv_system
  } = strings;
  if (role === "INITIATEUR") {
    return _initializer;
  }
  if (role === "MANAGER_VALIDATION") {
    return _validator;
  }
  if (role === "PPV_VALIDATION") {
    return "WFM";
  }
  if (role === "RH_VALIDATION") {
    return _hr;
  }
  if (role === "SYSTEME") {
    return _system;
  }
  if (role === "SYSTEME_PPV") {
    return _ppv_system;
  } else {
    return role;
  }
};

/**
 * Responses that are not business exception are not shown in the message
 * @param payload
 * @returns {*}
 */
export const getToasterMessage = payload => {
  const {
    _INTERNAL_SERVER_ERROR
  } = strings;
  const { code, error } = payload;
  const getLocalizedMessage = payload => {
    const { code, message } = payload;
    return code === "UNSUPPORTED_ERROR" || !strings[`_${code}`]
      ? message || _INTERNAL_SERVER_ERROR
      : strings[`_${code}`];
  };
  return [code, error]
    .filter(e => !!e)
    .map(e => e.toUpperCase().replace(/ /g, "_"))
    .includes("INTERNAL_SERVER_ERROR")
    ? _INTERNAL_SERVER_ERROR
    : getLocalizedMessage(payload);
};

export const states = {
  APPROVED: _approved,
  PENDING: _pending,
  PENDING_RH: _pending,
  REJECTED: _rejected,
  CANCELED: _canceled
};


export const getStatus = () => {
  const {
    _approved,
    _pending,
    _rejected,
    _canceled,
  } = strings;
  const tab =
      { APPROVED: _approved,
        PENDING: _pending,
        PENDING_RH: _pending,
        REJECTED: _rejected,
        CANCELED: _canceled
      };
  return tab;

};

export const getTimeEntryType = (type, flag=false) => {
  switch (type) {
    case "DEL" : return "Délégation";
    case "TRA" : return "temps de trajet";
    case "AAP" : return "absence autorisée payé";
    case "CTM" : return "Capital temps maternité";
    case "VME" : return "Visite médicale";
    case "ADM" : return "Heures ADM autorisées";
    case "GRE" : return "grève";
    case "CSE" : return "Réunion CSE";
    case "ELU" : return "Réunion élu proximité";
    case "AST" : return "Astreinte";
    case "ARH" : return "Administratif RH";
    case "DED" : return "Délégation TAD";
    case "NOA" : return "Modulation NAO";
    default : return type;
  }
}
export const getHolidayType = (type, flag=false) => {
  const {
    _rttHoliday,
    _ancienneteHoliday,
    _payedHoliday,
    _unpayedHoliday,
    _exceptionalHoliday,
    _exceptionalAbsence,
    _recuperation,
    _casualHoliday
  } = strings;
  if (type === "PAIDHOLIDAY") {
    return _payedHoliday;
  }
  if (type === "RTTHOLIDAY") {
    return _rttHoliday;
  }
  if (type === "SENIORITYHOLIDAY") {
    return _ancienneteHoliday;
  }
  if (type === "UNPAIDHOLIDAY") {
    return _unpayedHoliday;
  }
  if (type === "EXCEPTIONALHOLIDAY") {
    return flag ?_exceptionalAbsence : _exceptionalHoliday;
  }
  if (type === "ENTRYRECUP") {
    return _recuperation;
  }
  if (type === "RECUP") {
    return _recuperation;
  }
  if (type === "CASUALLEAVE") {
    return _casualHoliday;
  }
  if (typeof type === "object" && type) {
    return type.label;
  } else {
    return type;
  }
};

export const getHolidaySubType = holiday => {
  const type = holiday.typeConge;
  const {
    _exceptionalHoliday
  } = strings;

  if (type === "EXCEPTIONALHOLIDAY") {
    const subType = holiday.exceptionalType;
    return subType ? subType.label : _exceptionalHoliday;
  }
  return '';

};

export const getMANHolidayType = type => {
  const {
    _payedHoliday,
    _unpayedHoliday,
    _recuperation,
    _unpaidSicknessHoliday
  } = strings;
  if (type === "PAIDHOLIDAY") {
    return _payedHoliday;
  }
  if (type === "UNPAIDHOLIDAY") {
    return _unpayedHoliday;
  }
  if (type === "EXCEPTIONALHOLIDAY") {
    // only visible to users with role MAN in added requests screen
    return _unpaidSicknessHoliday;
  }
  if (type === "ENTRYRECUP") {
    return _recuperation;
  }
  if (type === "RECUP") {
    return _recuperation;
  }
};

export const RequestStatusFormatter = ({ value }) => {
  const {
    _deleted,
    _approved,
    _pending,
    _rejected,
    _canceled,
    _added
  } = strings;
  if (value === "REJECTED") {
    return <div style={{ fontWeight: "bold", color: "red" }}>{_rejected}</div>;
  }
  if (value === "CANCELED") {
    return (
      <div style={{ fontWeight: "bold", color: "black" }}>{_canceled}</div>
    );
  }
  if (value === "APPROVED") {
    return (
      <div style={{ fontWeight: "bold", color: "green" }}>{_approved}</div>
    );
  }
  if (value === "ADDED") {
    return <div style={{ fontWeight: "bold", color: "black" }}>{_added}</div>;
  }
  if (value === "DELETED") {
    return <div style={{ fontWeight: "bold", color: "black" }}>{_deleted}</div>;
  }
  if (value === "PENDING") {
    return <div style={{ fontWeight: "bold", color: "balck" }}>{_pending}</div>;
  }
  if (value === "PENDING_RH") {
    return <div style={{ fontWeight: "bold", color: "balck" }}>{_pending}</div>;
  } else {
    return <div style={{ fontWeight: "bold", color: "black" }}>{value}</div>;
  }
};

const ToJustifyFormatter = ({ value }) => {
  return value ? (
    <CheckIcon style={{ color: "green" }} />
  ) : (
    <ErrorIcon style={{ color: "red" }} />
  );
};

const UserStatusFormatter = ({ value }) => {
  return value ? (
    <CheckIcon style={{ color: "green" }} />
  ) : (
    <ErrorIcon style={{ color: "grey" }} />
  );
};

export const ToJustifyTypeProvider = props => (
  <DataTypeProvider formatterComponent={ToJustifyFormatter} {...props} />
);

export const UserStatusTypeProvider = props => (
  <DataTypeProvider formatterComponent={UserStatusFormatter} {...props} />
);

export const HolidayTypeFormatter = (prop) => {
  return getHolidayType(prop.value,
    (prop.row.addedForEmp && prop.row.addedForEmp.registrationNumber && prop.row.addedForEmp.country.id == 5) ||
    (prop.row.employeeCountryCode == 'FR') || (prop.row.id && prop.row.id.employeeCountryCode == 'FR')
    );
};

export const HolidaySubTypeFormatter = ({ value }) => {
  return getHolidaySubType(value);
};

export const MANHolidayTypeFormatter = ({ value }) => {
  return getMANHolidayType(value);
};

export const RequestTypeFormatter = ({ value }) => {
  return getRequestType(value);
};
export const RequestTypeProvider = props => (
  <DataTypeProvider formatterComponent={RequestTypeFormatter} {...props} />
);

export const IdEditor = () => {
  return <div></div>;
};

export const getRequestType = type => {
  const {
    _cancelationRequest,
    _absenceRequest
  } = strings;
  if (type === "CANCELLATION") {
    return _cancelationRequest;
  }
  if (type === "REQUEST") {
    return _absenceRequest;
  }

  return "";

};

export const CountryFormatter = ({ value }) => {
  const src = `${FLAGS_CDN}/${RefData.COUNTRY_CODES[value.label]}/flat/64.png`;
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: -7, left: 0 }}>
        <Avatar alt="#" style={{ height: 20, width: 20 }} src={src} />
      </div>
      <div style={{ position: "absolute", top: -5, left: 25 }}>
        {value.label}{" "}
      </div>
    </div>
  );
};

export const CriticalityFormatter = ({ value }) => {
  const {
    _minor,
    _major,
    _normal
  } = strings;
  if (value === "MINOR") {
    return (
      <div style={{ fontWeight: "bold", color: "steelblue" }}>{_minor}</div>
    );
  }
  if (value === "NORMAL") {
    return <div style={{ fontWeight: "bold", color: "balck" }}>{_normal}</div>;
  } else {
    return <div style={{ fontWeight: "bold", color: "red" }}>{_major}</div>;
  }
};

export const StateFormatter = ({ value }) => {
  const {
    _enabled,
    _disabled
  } = strings;
  if (value === "ENABLED") {
    return <div style={{ fontWeight: "bold", color: "green" }}>{_enabled}</div>;
  } else {
    return <div style={{ fontWeight: "bold", color: "red" }}>{_disabled}</div>;
  }
};

export const EmployeeFormatter = ({ value }) => {
  const Emp = value.constructor === Array ? value[0] : value;

  if (Emp) {
    const { firstName, lastName, registrationNumber } = Emp;
    return (
      <MGrid container>
        <MGrid item sm={4}>
          <Avatar
            src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${registrationNumber}`}
          />
        </MGrid>
        <MGrid item sm={8} style={{ paddingTop: 5 }}>
          <MGrid container direction={"column"}>
            <MGrid item>
              <Tooltip title={`${firstName} ${lastName}`} placement="right-end">
                <Typography gutterBottom>
                  {`${firstName} ${lastName}`}
                </Typography>
              </Tooltip>
            </MGrid>
            <MGrid item>{registrationNumber}</MGrid>
          </MGrid>
        </MGrid>
      </MGrid>
    );
  } else {
    return <div>{value.toString()}</div>;
  }
};

export const EmployeeFormatterExport = ({ value }) => {
  const { firstName } = value;

  if (firstName !== "") {
    const { firstName, lastName, userName, registrationNumber } = value;
    return (
      <MGrid container>
        <MGrid item sm={4}>
          <Avatar
            src={`${API}/${MEDIUM_AVATAR_CORE_SERVER}/${userName ||
              registrationNumber}`}
          />
        </MGrid>
        <MGrid item sm={8} style={{ paddingTop: 5 }}>
          <MGrid container direction={"column"}>
            <MGrid item>
              <Tooltip title={`${firstName} ${lastName}`} placement="right-end">
                <Typography gutterBottom>
                  {`${firstName} ${lastName}`}
                </Typography>
              </Tooltip>
            </MGrid>
            <MGrid item>{userName}</MGrid>
          </MGrid>
        </MGrid>
      </MGrid>
    );
  } else {
    return <></>;
  }
};

const styles = theme => ({
  cell: {
    width: "100%",
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  input: {
    width: "100%"
  },
  rangeCell: {
    width: "100%",
    minWidth: 120,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  }
});

class DurationFilterCellBase extends React.Component {
  state = {
    duration: null
  };

  componentDidMount() {
    this.setState({ duration: null });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    const { duration } = this.state;
    return (
      <TableCell className={classes.cell}>
        <Input
          className={classes.input}
          type="number"
          value={duration}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ duration: e.target.value });
          }}
          placeholder=" "
          inputProps={{
            style: { textAlign: "right", height: "inherit" },
            min: 1
          }}
        />
      </TableCell>
    );
  }
}

const DurationFilterCell = withStyles(styles, { name: "DurationFilterCell" })(
  DurationFilterCellBase
);

const DisabledFilterCellBase = ({ filter, onFilter, classes }) => (
  <TableCell className={classes.cell}>
    <Input
      className={classes.input}
      disabled={true}
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
      placeholder=" "
      inputProps={{
        style: { textAlign: "right", height: "inherit" },
        min: 1,
        max: 100
      }}
    />
  </TableCell>
);
const DisabledFilterCell = withStyles(styles, { name: "DisabledFilterCell" })(
  DisabledFilterCellBase
);

class EmployeeFilterCellBase extends React.Component {
  state = {
    registrationNumber: ""
  };

  componentWillMount() {
    this.setState({ registrationNumber: null });
    this.props.onFilter(null);
  }

  render() {
    const { registrationNumber } = this.state;
    const { onFilter, classes } = this.props;
    return (
      <TableCell className={classes.cell}>
        <Input
          className={classes.input}
          value={registrationNumber}
          onChange={e => {
            const registrationNumber = e.target.value;
            if (registrationNumber !== "") {
              if (registrationNumber.length >= 5) {
                onFilter(
                  registrationNumber ? { value: registrationNumber } : null
                );
                this.setState({ registrationNumber });
              }
              this.setState({ registrationNumber });
            } else {
              onFilter(null);
              this.setState({ registrationNumber });
            }
          }}
          placeholder="Matricule"
        />
      </TableCell>
    );
  }
}

const EmployeeFilterCell = withStyles(styles, { name: "EmployeeFilterCell" })(
  EmployeeFilterCellBase
);

class DateFilterCellBase extends React.Component {
  state = {
    focused: false,
    date: null
  };

  componentDidMount() {
    this.setState({ date: null });
    this.props.onFilter(null);
  }

  render() {
    const { focused, date } = this.state;
    const { filter, onFilter, classes } = this.props;
    return (
      <TableCell className={classes.cell}>
        <SingleDatePicker
          small={true}
          placeholder={" "}
          isOutsideRange={day => {
            return false;
          }}
          numberOfMonths={1}
          noBorder={true}
          block={true}
          showClearDate={true}
          hideKeyboardShortcutsPanel={true}
          onDateChange={date => {
            this.setState({ date });
            onFilter(
              date ? { value: moment(date).format("DD/MM/YYYY") } : null
            );
          }}
          onFocusChange={({ focused }) => this.setState({ focused })}
          focused={focused}
          date={date}
        />
      </TableCell>
    );
  }
}

const DateFilterCell = withStyles(styles, { name: "DateFilterCell" })(
  DateFilterCellBase
);

class DateRangeFilterCellBase extends React.Component {
  state = {
    startDate: "",
    endDate: "",
    focusedInputRange: ""
  };

  componentDidMount() {
    this.setState({ date: null });
    this.props.onFilter({ value: { startDate: "", endDate: "" } });
  }

  onDatesChange = (startDate, endDate) => {
    if (endDate == null) {
      this.setState({ startDate: startDate, endDate: endDate });
      return null;
    } else {
      this.setState({ startDate: startDate, endDate: endDate });
      this.props.onFilter({
        value: {
          startDate: moment(startDate).format("DD/MM/YYYY"),
          endDate: moment(endDate).format("DD/MM/YYYY")
        }
      });
    }
  };

  render() {
    const { startDate, endDate, focusedInputRange } = this.state;
    const { filter, onFilter, classes } = this.props;
    const {
      _startDate,
      _returnDate
    }= strings;
    return (
      <TableCell className={classes.cell}>
        <DateRangePicker
          small={true}
          startDateId="start"
          endDateId="end"
          startDate={startDate}
          noBorder={true}
          endDate={endDate}
          isOutsideRange={() => false}
          startDatePlaceholderText={_startDate}
          endDatePlaceholderText={_returnDate}
          hideKeyboardShortcutsPanel={true}
          onDatesChange={({ startDate, endDate }) => {
            this.onDatesChange(startDate, endDate);
          }}
          focusedInput={focusedInputRange}
          onFocusChange={focusedInputRange => {
            this.setState({ focusedInputRange });
          }}
        />
      </TableCell>
    );
  }
}

const DateRangeFilterCell = withStyles(styles, { name: "DateRangeFilterCell" })(
  DateRangeFilterCellBase
);

function getMenuItems(items) {
  return items.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));
}

class StatusFilterCellBase extends React.Component {
  state = { value: "" };

  componentWillMount() {
    this.setState({ value: "" });
    this.props.onFilter(null);
  }

  render() {
    const {
      _approved,
      _pending,
      _rejected,
      _canceled,
      _all,
    } = strings;
    const { filter, onFilter, classes } = this.props;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.value}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ value: e.target.value });
          }}
        >
          {getMenuItems([
            { value: "APPROVED", label: _approved },
            { value: "PENDING", label: _pending },
            { value: "PENDING_RH", label: _pending },
            { value: "REJECTED", label: _rejected },
            { value: "CANCELED", label: _canceled },
            { value: "", label: _all }
          ])}
        </Select>
      </TableCell>
    );
  }
}

const StatusFilterCell = withStyles(styles, { name: "RequestTypeFilterCell" })(
  StatusFilterCellBase
);

class ToJustifyFilterCellBase extends React.Component {
  state = { value: true };

  componentWillMount() {
    this.setState({ value: this.props.initValue });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    const {
      _no,
      _yes
    } = strings;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.value}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ value: e.target.value });
          }}
        >
          {getMenuItems([
            { value: false, label: _no },
            { value: true, label: _yes }
          ])}
        </Select>
      </TableCell>
    );
  }
}

const ToJustifyFilterCell = withStyles(styles, { name: "ToJustifyFilterCell" })(
  ToJustifyFilterCellBase
);

class UserStatusFilterCellBase extends React.Component {
  state = { value: true };

  componentWillMount() {
    this.setState({ value: true });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    const {
      _no,
      _yes
    } = strings;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.value}
          onChange={e => {
            onFilter({ value: e.target.value });
            this.setState({ value: e.target.value });
          }}
        >
          {getMenuItems([
            { value: false, label: _no },
            { value: true, label: _yes }
          ])}
        </Select>
      </TableCell>
    );
  }
}

const UserStatusFilterCell = withStyles(styles, {
  name: "UserStatusFilterCellBase"
})(UserStatusFilterCellBase);

class RecuperationStatusFilterCellBase extends React.Component {
  state = { value: "" };

  componentWillMount() {
    this.setState({ value: "" });
    this.props.onFilter(null);
  }

  render() {
    const {
      _consumed,
      _all,
      _expired,
      _active,
    } = strings;
    const { filter, onFilter, classes } = this.props;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.value}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ value: e.target.value });
          }}
        >
          {getMenuItems([
            { value: "CONSUMED", label: _consumed },
            { value: "EXPIRED", label: _expired },
            { value: "ACTIVE", label: _active },
            { value: "", label: _all }
          ])}
        </Select>
      </TableCell>
    );
  }
}

const RecuperationStatusFilterCell = withStyles(styles, {
  name: "RecuperationStatusFilterCell"
})(RecuperationStatusFilterCellBase);

class LabelFilterCellBase extends React.Component {
  state = { value: "" };

  componentWillMount() {
    this.setState({ value: "" });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    return (
      <TableCell className={classes.cell}>
        <Input
          className={classes.input}
          placeholder=" "
          value={this.state.value}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ value: e.target.value });
          }}
        />
      </TableCell>
    );
  }
}

const LabelFilterCell = withStyles(styles, { name: "LabelFilterCell" })(
  LabelFilterCellBase
);

class RequestTypeFilterCellBase extends React.Component {
  state = {
    typeRequest: ""
  };

  componentDidMount() {
    this.setState({ typeRequest: null });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    const {
      _cancelationRequest,
      _absenceRequest,
      _all,
    } = strings;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.typeRequest}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ typeRequest: e.target.value || "" });
          }}
        >
          {getMenuItems([
            { value: "", label: _all },
            { value: "REQUEST", label: _absenceRequest },
            { value: "CANCELLATION", label: _cancelationRequest }
          ])}
        </Select>
      </TableCell>
    );
  }
}

const RequestTypeFilterCell = withStyles(styles, {
  name: "RequestTypeFilterCell"
})(RequestTypeFilterCellBase);

class StateFilterCellBase extends React.Component {
  state = { value: null };

  componentWillMount() {
    this.setState({ value: null });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    const {
      _enabled,
      _disabled,
      _all,
    } = strings;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.value}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ value: e.target.value });
          }}
        >
          {getMenuItems([
            { value: "ENABLED", label: _enabled },
            { value: "DISABLED", label: _disabled },
            { value: "", label: _all }
          ])}
        </Select>
      </TableCell>
    );
  }
}

const StateFilterCell = withStyles(styles, { name: "StateFilterCell" })(
  StateFilterCellBase
);

class HolidayTypeFilterCellBase extends React.Component {
  state = { type: null };

  componentWillMount() {
    this.setState({ type: null });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    const { configUser: {countryCode} } = store.getState().references;
    const {
      _payedHoliday,
      _unpayedHoliday,
      _exceptionalHoliday,
      _casualHoliday,
      _all,
      _exceptionalAbsence
    } = strings;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.type}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ type: e.target.value });
          }}
        >
          {getMenuItems(countryCode == 13 ? [
            {
              value: "PAIDHOLIDAY",
              label: _payedHoliday
            },
            {
              value: "EXCEPTIONALHOLIDAY",
              label: countryCode == 5 ? _exceptionalAbsence : _exceptionalHoliday
            },
            {
              value: "UNPAIDHOLIDAY",
              label: _unpayedHoliday
            },
            {
              value: "CASUALLEAVE",
              label: _casualHoliday
            },
            { value: "", label: _all }
          ] :
              [
                {
                  value: "PAIDHOLIDAY",
                  label: _payedHoliday
                },
                {
                  value: "EXCEPTIONALHOLIDAY",
                  label: countryCode == 5 ? _exceptionalAbsence : _exceptionalHoliday
                },
                {
                  value: "UNPAIDHOLIDAY",
                  label: _unpayedHoliday
                },
                { value: "", label: _all }
              ])}
        </Select>
      </TableCell>
    );
  }
}

const HolidayTypeFilterCell = withStyles(styles, {
  name: "HolidayTypeFilterCell"
})(HolidayTypeFilterCellBase);

class YearFilterCellBase extends React.Component {
  state = { year: null };

  componentWillMount() {
    this.setState({ year: null });
    this.props.onFilter(null);
  }

  render() {
    const { filter, onFilter, classes } = this.props;
    let momentEdit =  moment().add(2, 'years');
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.year}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ year: e.target.value });
          }}
        >
          {getMenuItems(
            [...Array(6).keys()]
              .map(i =>
                {
                  momentEdit =  moment().add(2, 'years');
                  return String(
                  momentEdit.subtract(i, "years")
                    .year());
                  }
              )
              .map(year => {
                console.log({ value: year, label: year });

                return { value: year, label: year };
              })
          )}
        </Select>
      </TableCell>
    );
  }
}

const YearFilterCell = withStyles(styles, { name: "YearFilterCell" })(
  YearFilterCellBase
);

class CriticalityFilterCellBase extends React.Component {
  state = { value: null };

  componentWillMount() {
    this.setState({ value: null });
    this.props.onFilter(null);
  }

  render() {
    const {
      _minor,
      _major,
      _normal,
      _all,
    } = strings;
    const { filter, onFilter, classes } = this.props;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.value}
          onChange={e => {
            onFilter(e.target.value ? { value: e.target.value } : null);
            this.setState({ value: e.target.value });
          }}
        >
          {getMenuItems([
            { value: "MAJOR", label: _major },
            { value: "MINOR", label: _minor },
            { value: "NORMAL", label: _normal },
            { value: "", label: _all }
          ])}
        </Select>
      </TableCell>
    );
  }
}

const CriticalityFilterCell = withStyles(styles, {
  name: "CriticalityFilterCell"
})(CriticalityFilterCellBase);

class CountryFilterCellBase extends React.Component {
  state = { selectedCountry: null };

  getCountryById = (id, countries) => {
    const country = countries.filter(country => country.id === id);
    if (country.length > 0) {
      return country[0];
    } else {
      return null;
    }
  };

  render() {
    const { filter, onFilter, classes } = this.props;
    const { activeCountries } = store.getState().references;
    return (
      <TableCell className={classes.cell}>
        <Select
          className={classes.input}
          value={this.state.selectedCountry || ""}
          onChange={e => {
            const selectedCountry = this.getCountryById(
              e.target.value,
              activeCountries
            );
            onFilter(selectedCountry ? { value: selectedCountry } : null);
            this.setState({ selectedCountry: "CAMEROUN" });
          }}
        >
          {getMenuItems(
            activeCountries.map(country => {
              return { label: country.label, value: country.id };
            })
          )}
        </Select>
      </TableCell>
    );
  }
}

const CountryFilterCell = withStyles(styles, { name: "CountryFilterCell" })(
  CountryFilterCellBase
);

export const FilterCell = props => {
  const { column } = props;
  if (["toExportDuration", "totalDuration", "duration"].includes(column.name)) {
    return <DurationFilterCell {...props} />;
  } else if (
    [
      "requestReturnDate",
      "requestStartDate",
      "endDate",
      "returnDate",
      "startDate"
    ].includes(column.name)
  ) {
    return <DateFilterCell {...props} />;
  } else if (column.name === "status") {
    return <StatusFilterCell {...props} />;
  } else if (column.name === "typeRequest") {
    return <RequestTypeFilterCell {...props} />;
  } else if (column.name === "state") {
    return <StateFilterCell {...props} />;
  } else if (["typeConge", "holidayType"].includes(column.name)) {
    return <HolidayTypeFilterCell {...props} />;
  } else if (column.name === "country") {
    return <CountryFilterCell {...props} />;
  } else if (column.name === "criticality") {
    return <CriticalityFilterCell {...props} />;
  } else if (column.name === "addedForEmp") {
    return <EmployeeFilterCell {...props} />;
  } else if (
    [
      "requestDate",
      "errorMsg",
      "comment",
      "labelTeleopti",
      "start",
      "end",
      "$label",
      "provedByDocuments",
      "requestUpdateDate",
      "$startDate",
      "updateDate",
      "reasonOfRejecti,on",
      "prenom",
      "nom"
    ].includes(column.name)
  ) {
    return <DisabledFilterCell {...props} />;
  } else if (column.name === "addedByEmp") {
    return <EmployeeFilterCell {...props} />;
  } else if (column.name === "$endDate") {
    return <YearFilterCell {...props} />;
  } else if (column.name === "recuperationStatus") {
    return <RecuperationStatusFilterCell {...props} />;
  } else if (column.name === "label") {
    return <LabelFilterCell {...props} />;
  } else if (column.name === "abbreviation") {
    return <LabelFilterCell {...props} />;
  } else if (["forJustify", "exported", "justified"].includes(column.name)) {
    return (
      <ToJustifyFilterCell {...props} initValue={"active" === column.name} />
    );
  } else if (column.name === "matricule") {
    return <LabelFilterCell {...props} />;
  } else if (["absenceDay", "$start"].includes(column.name)) {
    return <DateRangeFilterCell {...props} />;
  } else if (["active"].includes(column.name)) {
    return <UserStatusFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};

export const RequestStatusProvider = props => (
  <DataTypeProvider formatterComponent={RequestStatusFormatter} {...props} />
);

export const EmployeeProvider = props => (
  <DataTypeProvider formatterComponent={EmployeeFormatter} {...props} />
);

export const EmployeeProviderExport = props => (
  <DataTypeProvider formatterComponent={EmployeeFormatterExport} {...props} />
);

export const HolidayTypeProvider = props => (
  <DataTypeProvider formatterComponent={HolidayTypeFormatter} {...props} />
);

export const HolidaySubTypeProvider = props => (
  <DataTypeProvider formatterComponent={HolidaySubTypeFormatter} {...props} />
);

export const MANHolidayTypeProvider = props => (
  <DataTypeProvider formatterComponent={MANHolidayTypeFormatter} {...props} />
);

export const Root = props => (
  <Grid.Root {...props} style={{ height: "100%", overflow: "visible" }} />
);

export function sortByColumnTypeDate(columnNames) {
    return columnNames.map(c => {
        return {
            columnName: c,
            compare: (a1, b1) => {
                const a = new Date(a1).getTime();
                const b = new Date(b1).getTime();
                return a == b ? 0 : a < b ? -1 : 1;
            }
        }
    });
}
/* Function Translate */
export function formatString(key, args) {
    args = Array.isArray(args) ? args : [args];
    args.forEach((arg) => { key = String(key).replace('{}', arg); })
    return key;
}